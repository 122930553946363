<script setup lang="ts">

type TextColor = 'dark' | 'light';

const { textColor } = withDefaults(
	defineProps<{
		textColor?: TextColor,
	}>(),
	{
		textColor: 'dark'
	}
);

const {openSubmitMenu, openBurgerMenu} = useModal();

const route = useRoute();
const links = [
	{
		href: 'index',
		text: 'О нас',
		icon: 'nav/home'
	},
	{
		href: 'flats',
		text: 'Квартиры',
		icon: 'nav/flat'
	},
	{
		href: 'gallery',
		text: 'Галлерея',
		icon: 'nav/gallery'
	},
	{
		href: 'documents',
		text: 'Документы',
		icon: 'nav/docs'
	},
	{
		href: 'contacts',
		text: 'Контакты',
		icon: 'nav/contacts'
	},
];


const computedLinks = computed(() => {
	return links.map(link => ({
		...link,
		isActive: route.fullPath.indexOf(link.href) > 0 || route.name === link.href
	}))
});

</script>

<template>
		<nav
			:class="['nav', {
				'nav_white': textColor === 'light'
			}]"
			:style="{
				background: textColor !== 'light' ? '#fff' : 'none'
			}"
		>
			<div class="nav__desktop wrapper">
				<div class="nav__links">
					<NuxtLink to="/" class="link_logo">
						<NuxtIcon name="riverplaza" filled class="logo"/>
					</NuxtLink>
					<NuxtLink
						v-for="{isActive, icon, text, href} of computedLinks"
						:class="['nav__link link', {
							'active': isActive
						}]"
						:to="{name: href}"
					>
						<NuxtIcon
							:name="icon"
							class="link__icon"
							filled
						/>
						<span class="link__text">{{text}}</span>
					</NuxtLink>
				</div>
				<div class="nav__right">
					<a class="nav__phone" href="tel:+7 (938) 450-99-88">
						+7 (938) 450-99-88
					</a>
					<v-btn variant="flat" class="nav__call-back" @click="openSubmitMenu('for-call')">Заказать звонок</v-btn>
				</div>
			</div>
			<div class="nav__mobile wrapper">
				<NuxtLink to="/" class="nav__logo">
					<NuxtIcon name="riverplaza" filled class="logo"/>
					<h3 >
						Ривер Плаза
					</h3>
				</NuxtLink>
				<div class="nav__btns">
					<VBtn  variant="tonal" color="#FAAC37" icon="mdi-phone-in-talk-outline" @click="openSubmitMenu('for-call')" aria-label="Name"></VBtn>
<!--					<NuxtIcon name="nav/call" class="nav__call" filled></NuxtIcon>-->
					
					<VBtn :icon="true" variant="tonal" v-ripple color="#FAAC37" aria-label="Name">
						<NuxtIcon name="nav/burger" class="nav__burger" filled @click="openBurgerMenu"/>
					</VBtn>
				</div>
			</div>
			
		</nav>

</template>

<style scoped lang="scss">

.wrapper {
	padding: 16px;
}
@media screen and (max-width: 1080px) {
	.nav__call-back {
		display: none;
	}
}

@media screen and (max-width: 880px) {
	.nav__phone {
		display: none;
	}
}

@media screen and (max-width: 790px) {
	.nav {
		&__desktop {
			display: none !important;
		}
		&__mobile {
			display: flex !important;
			justify-content: space-between;
			align-items: center;
		}
	}
}


.nav {
	width: 100%;
	
	&_white &__logo {
		opacity: 0;
		
	}
	
	&__logo {
		opacity: 1;
		font-size: 24px;
		font-family: 'CasperBold', sans-serif;
		text-decoration: none;
		color: $text-main;
		display: flex;
		align-items: center;
		gap: 8px;
		
		h3 {
			margin-top: 3px;
			font-size: 20px;
			font-family: 'CasperBold', sans-serif;
			
		}
	}
	
	
	
	&__mobile {
		display: none;
	}
	
	&__btns {
		display: flex;
		align-items: center;
		gap: 16px;
	}
	
	
	&_white &__phone {
		color: #FFFFFF;
	}
	&__right {
		display: flex;
		align-items: center;
		gap: 16px;
	}
	
	&__phone {
		font-weight: bold;
		color: $text-main;
	}
	
	&__desktop {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 100%;
	}
	&__links {
		display: flex;
		gap: 8px;
	}
	
	&_white .link__text {
		color: #FFFFFF;
	}
	
}

.link {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 16px;
	text-decoration: none;
	padding: 8px 12px;
	border-radius: 7px;
	&.active &__text {
		color: $accent;
	}
	
	&_logo {
		margin-right: 8px;
	}
	transition: .3s ease;
	@media screen and (min-width: 800px) {
		&:hover {
			background: rgba($accent, 0.12);
		}
	}
	
	&__text {
		display: block;
		font-family: 'CasperBold', sans-serif;
		color: $text-main;
	}
}

</style>